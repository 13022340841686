
import { h, ref, nextTick, reactive } from 'vue';
import Login from '@/components/Login.vue'
import PublishRoast from '@/views/publish/PublishRoast.vue'
export const showLogin = (instance: any) => {
  const that = instance;
  console.log('that', that)
  that.$msgbox({
    title: '登录',
    message: h(Login),
    showCancelButton: true,
    confirmButtonText: '登录',
    cancelButtonText: '取消',
    beforeClose: (action: any, instance: any, done: any) => {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true;
        instance.confirmButtonText = '执行中...';
        setTimeout(() => {
          done();
          setTimeout(() => {
            instance.confirmButtonLoading = false;
          }, 300);
        }, 3000);
      } else {
        done();
      }
    }
  }).then((action: any) => {
    that.$message({
      type: 'info',
      message: 'action: ' + action
    });
  });
}


export const showRoast = (instance: any) => {
  const that = instance;
  console.log('that', that)
  //const compRef = ref();
  let proxy: any = null
  const onConfirm = () => {

  }
  const onMounted = (ins: any) => {
    console.log('instance===', ins)
    proxy = ins.proxy;
  }
  const compRef = ref()
  that.$msgbox({
    title: '发布吐槽',
    width: '50%',
    message: h(PublishRoast, { onConfirm, onMounted }),
    showCancelButton: true,
    confirmButtonText: '发布',
    cancelButtonText: '取消',
    beforeClose: (action: any, instance: any, done: any) => {
      if (action === 'confirm') {
        instance.confirmButtonLoading = true;
        instance.confirmButtonText = '执行中...';
        nextTick(() => {
          console.log("pPublishRoast", PublishRoast, proxy)
          if (proxy) {
            const method = proxy.dialogSubmit || proxy.$.exposed.dialogSubmit
            if (method) {
              method().then((bol: boolean) => {
                console.log('bol-=-',bol)
                instance.confirmButtonText = '发布';
                instance.confirmButtonLoading = false;
                if (bol) {
                  done();
                }
              })
            } else {
              alert('error')
            }
          }
        })

      } else {
        done();
      }
    }
  }).then((action: any) => {
    that.$message({
      type: 'info',
      message: 'action: ' + action
    });
  });

}



