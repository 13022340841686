<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName" />
  </svg>
</template>

<script>
import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    const iconName = computed(() => `#icon-${props.icon}`);
    const svgClass = computed(() => {
      if (props.className) {
        return 'svg-icon ' + props.className;
      } else {
        return 'svg-icon';
      }
    });

    return {
      iconName,
      svgClass,
    };
  },
});
</script>
