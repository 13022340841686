import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import component from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      name_cn: '首页',
    },
  },
  {
    path: '/user/login',
    name: 'user_login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/user/register',
    name: 'user_register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/coop',
    name: 'coop',
    component: () => import('../views/Coop.vue'),
    meta: {
      name_cn: '找合作',
    },
  },
  {
    path: '/match/:id',
    name: 'match_detail',
    component: () => import('../views/MatchDetail.vue'),
    meta: {
      name_cn: '赛事详情',
    },
  },
  {
    path: '/resource/:id',
    name: 'resource_detail',
    component: () => import('../views/ResourceDetail.vue'),
    meta: {
      name_cn: '找资源',
    },
  },
  {
    path: '/member/:id',
    name: 'member_detail',
    component: () => import('../views/MemberDetail.vue'),
    meta: {
      name_cn: '人才详情',
    },
  },
  {
    path: '/resource',
    name: 'resource',
    component: () => import('../views/Resource.vue'),
    meta: {
      name_cn: '找资源',
    },
  },
  {
    path: '/member',
    name: 'member',
    component: () => import('../views/Member.vue'),
    meta: {
      name_cn: '找人才',
    },
  },
  {
    path: '/coop/:id',
    name: 'coop_detail',
    component: () => import('../views/ProjectDetail.vue'),
    meta: {
      name_cn: '找合作',
    },
  },
  {
    path: '/fund/:id',
    name: 'fund_detail',
    component: () => import('../views/ProjectDetail.vue'),
    meta: {
      name_cn: '找资金',
    },
  },
  {
    path: '/project/:id',
    name: 'project_detail',
    component: () => import('../views/ProjectDetail.vue'),
    meta: {
      name_cn: '找项目',
    },
  },
  {
    path: '/news/:id',
    name: 'news_detail',
    component: () => import('../views/NewsDetail.vue'),
    meta: {
      name_cn: '新闻',
    },
  },
  {
    path: '/project',
    name: 'project',
    component: () => import('../views/Project.vue'),
    meta: {
      name_cn: '找项目',
    },
  },
  {
    path: '/work',
    name: 'work',
    component: () => import('../views/ProjectMin.vue'),
    meta: {
      name_cn: '找活',
    },
  },
  {
    path: '/fund',
    name: 'fund',
    component: () => import('../views/Fund.vue'),
    meta: {
      name_cn: '找资金',
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/News.vue'),
    meta: {
      name_cn: '资讯',
    },
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('../views/App.vue'),
    meta: {
      name_cn: '月光宝盒',
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    meta: {
      name_cn: '关于',
    },
    children: [
      {
        path: 'service',
        name: 'service',
        component: () => import('../views/about/Service.vue'),
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import('../views/about/Privacy.vue'),
      },
    ],
  },
  {
    path: '/help',
    name: 'Help',
    children: [
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/help/About.vue'),
      },
      {
        path: 'bd',
        name: 'Bd',
        component: () => import('../views/help/Bd.vue'),
      },
      {
        path: 'complain',
        name: 'complain',
        component: () => import('../views/help/Complain.vue'),
      },
      {
        path: 'contact',
        name: 'Contact',
        component: () => import('../views/help/Contact.vue'),
      },
      {
        path: 'service',
        name: 'service',
        component: () => import('../views/help/Service.vue'),
      },
      {
        path: 'join',
        name: 'Join',
        component: () => import('../views/help/Join.vue'),
      },
      {
        path: 'ugc',
        name: 'ugc',
        component: () => import('../views/help/Ugc.vue'),
      },
    ],
  },
  {
    path: '/publish/news',
    name: 'publish_news',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/publish/PublishNews.vue'
      ),
    meta: {
      name_cn: '发布新闻',
    },
  },
  {
    path: '/publish/project',
    name: 'publish_project',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/publish/PublishProject.vue'
      ),
    meta: {
      name_cn: '发布',
    },
  },
  {
    path: '/publish/fund',
    name: 'publish_fund',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/publish/PublishFund.vue'
      ),
    meta: {
      name_cn: '发布资金',
    },
  },
  {
    path: '/publish/coop',
    name: 'publish_coop',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/publish/PublishCoop.vue'
      ),
    meta: {
      name_cn: '发布合伙',
    },
  },
  {
    path: '/publish/app',
    name: 'publish_app',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/publish/PublishApp.vue'),
    meta: {
      name_cn: '发布资源目',
    },
  },
  {
    path: '/comment',
    name: 'comment',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Comment.vue'),
    meta: {
      name_cn: '吐槽',
    },
  },
  {
    path: '/publish/resource',
    name: 'publish_app',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/publish/PublishResource.vue'
      ),
    meta: {
      name_cn: '发布群信息',
    },
  },
  {
    path: '/game',
    name: 'game',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Game.vue'),
    meta: {
      name_cn: '抽奖',
    },
  },
  {
    path: '/ucenter',
    name: 'ucenter',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/UserCenter.vue'),
    meta: {
      name_cn: '用户中心',
    },
    children: [
      {
        path: 'my',
        name: 'my',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ucenter/My.vue'),
      },
      {
        path: 'pay',
        name: 'pay',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ucenter/Pay.vue'),
      },
      {
        path: 'vip',
        name: 'vip',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ucenter/Vip.vue'),
      },
      {
        path: 'fav',
        name: 'fav',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ucenter/Fav.vue'),
      },
      {
        path: 'help',
        name: 'help',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ucenter/Help.vue'),
      },
      {
        path: 'wallet',
        name: 'wallet',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ucenter/Wallet.vue'),
      },
      {
        path: 'join',
        name: 'join',
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ucenter/Join.vue'),
      },
      {
        path: 'publish',
        name: 'publish',
        component: () =>
          import(
            /* webpackChunkName: "about" */ '../views/ucenter/Publish.vue'
          ),
        meta: {
          name_cn: '我的发布',
        },
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
