require('./demo')

export const domutil = {
  addClass: (element: any, className: string) => {
    if (element && className) {
      element.classList.add(className)
    }
  },
  removeClass: (element: any, className: string) => {
    if (element && className) {
      element.classList.remove(className)
    }
  },
}
export const urlutil = {
  addParams:(url:string, name:string, value:any )=>{
    if( url.indexOf('?')==-1){
      url = url+'?'+name+'='+value
    }else{
      url = url+'&'+name+'='+value;
    }
    return url;
  },
  removeParams:(url:string,name:string)=>{

  }
}
export const copyText = async (str: string) => {
  try {
    navigator.clipboard.writeText(str)
  } catch (err) {
    const ele = document.createElement('textarea')
    ele.style.cssText =
      'position:absolute;left:-10000px;top:-1000px;height:1px;width:1px'
    document.body.appendChild(ele)
    ele.value = str
    ele.focus()
    ele.select()
    document.execCommand('copy')
  }
}
